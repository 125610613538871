import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import useUser from '../hooks/useUser';

const IndexPage = () => {
  useEffect(() => {
    navigate('/home/', { replace: true });
  }, []);

  return <></>;
};

export default IndexPage;
